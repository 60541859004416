export const waUrl = 'https://wa.me/34675886560';
export const generateWaUrl = (txt) => encodeURI(`${waUrl}?text=${txt}`);
export const recaptchaKey = '6LdOkDMfAAAAAPR5kZzRomd_qBwfPn1U5sxa6yuc';
export const possibleSubjects = [
  'Alquiler velero con patrón',
  'Alquiler lanchas',
  'Consigue tu licencia de navegacion en un día',
  'Cursos PNB/PER',
];
export const baseApiUrl = 'https://api.lamangamarmenorcharter.com/';
export const baseApiUrlOld = 'https://apiold.lamangamarmenorcharter.com/';
