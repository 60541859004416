/* const name = {
  spa: "", 
  en: ""
}

const exp = 
{
  name,
  id: "",
  attributions: [
    {
      name: "",
      icon: null,
    },
  ],
  requirements: [
    {
      name: "",
      icon: null,
    },
  ],
  description: "",
  price: 0,
  images: [],
  theory: null,
}

export default exp */

import theory from "./theory";
import practice from "./practice";

const licenses = { theory, practice };
export default licenses;
